class ZIDXAccountAutorespondersTest implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountAutoresponderTestContainer";
    }
    getMatchURL(){
        return "/account/autoresponders/test";
    }
    render(){
        // console.log("autoresponders");
    }
}