class ZIDXAccountAgentLookup implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxAccountAgentLookupContainer";
    }

    getMatchURL() {
        return "/account/sites/agent-lookup";
    }

    render() {
        // console.log("site edit");
        let lookupTimeoutId=0;
        ZIDX.$("#lookup_text").on("input", function(this:HTMLInputElement){
            let lookupText=this.value;
            if(lookupText.length<3){
                return;
            }
            let field=this.getAttribute("data-field");
            let fieldElement=ZIDX.$("#"+field, window.parent.document)[0];
            let listing_index_id=this.getAttribute("data-index-id");
            let self=this;
            lookupTimeoutId=setTimeout(function() {
                clearTimeout(lookupTimeoutId);
                let obj = {
                    id: "ajaxAgentLookup",
                    method: "get",
                    postObj: {},
                    ignoreOldRequests: false,
                    callback: function (r: string) {
                        let js: any = JSON.parse(r);
                        // console.log(js);
                        let resultsElement=ZIDX.$("#lookup_results");
                        resultsElement.html("");
                        if (js.success) {
                            let arrLine = js.data.split("\\n");
                            resultsElement.append('<p>Select one of the agents below to add to the list</p>');
                            for (let i = 0; i < arrLine.length; i++) {
                                if(arrLine[i].trim().length==0) continue;
                                let arrRow = arrLine[i].split("\\t");
                                let agentName = arrRow[0];
                                let agentId = arrRow[1];
                                let officeName = arrRow[2];
                                // console.log(agentName, agentId);
                                let link=document.createElement("a");
                                link.onclick=function(){
                                    let ids=fieldElement.value.split(",");
                                    if(fieldElement.value.trim().length==""){
                                        ids=[];
                                    }
                                    if(ids.indexOf(agentId)==-1){
                                        ids.push(agentId);
                                        fieldElement.value=ids.join(",");
                                    }else{
                                        alert("Already added this agent");
                                    }
                                    // @ts-ignore
                                    window.parent.zCloseModal();
                                }
                                link.style.backgroundColor="rgba(128,128,128, .1)";
                                link.style.color="#fff";
                                link.style.padding="5px";
                                link.style.cursor="pointer";
                                link.title="Click to add this agent to the list";
                                link.style.margin="5px";
                                link.style.borderRadius="5px";
                                link.style.display="inline-block";
                                link.style.minWidth="300px";
                                link.style.width="45%";
                                link.innerHTML=ZIDXBase.firstLetterCaps(agentName) + ' (' + ZIDXBase.firstLetterCaps(officeName) + ')';
                                resultsElement.append(link);
                                // arrHTML.push('<button class="zidxAgentLookupButton" data-agent-id="' + agentId + '">' + agentName + ' (' + agentId + ')</button>'); // make a button that selects the agent id and adds it to the list of agents in parent window if it doesn't already have it
                                // make a button that selects the agent id and adds it to the list of agents in parent window if it doesn't already have it
                            }
                        }
                    },
                    errorCallback: function (e: XMLHttpRequest) {
                        console.log(e.status);
                    },
                    url: '/account/sites/agent-lookup/search?listing_index_id=' + listing_index_id + '&lookupText=' + lookupText
                };
                ZIDX.ajaxRequest(obj);
            }, 250);
        });
    }
}