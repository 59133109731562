import KeyUpEvent = JQuery.KeyUpEvent;

const LISTING_LAYOUT_LIST: number = 0;
const LISTING_LAYOUT_DETAIL: number = 1;
const LISTING_LAYOUT_MAP: number = 2;

class ZIDXListingSummary {
    type: string = "";
    id: string = "";
    feedId: number = 0;
    price: number = 0;
    latitude: number = 0;
    longitude: number = 0;
    bedrooms: number = 0;
    bathrooms: number = 0;
    halfBaths: number = 0;
    sqft: number = 0;
    acreage: number = 0;
    address: string = "";
    address2: string = "";
    city: string = "";
    state: string = "";
    postalCode: string = "";
    status: string = "";
    listStatus: string = "";
    photo: string = "";
    createdDatetime: number = 0;

    fixCase() {
        this.id = this.id.toUpperCase();
        this.address = ZIDXBase.firstLetterCaps(this.address);
        this.postalCode = this.postalCode.toUpperCase();
    }
}

class ZIDXListingData {
    listings: ZIDXListingSummary[] = [];
    totalListings: number = 0;
    perPage: number = 12;
    // get center
    averageLatitude: number = 0;
    averageLongitude: number = 0;
    locationCount: number = 0;
    minLatitude: number = 0;
    maxLatitude: number = 0;
    minLongitude: number = 0;
    maxLongitude: number = 0;
    forceZoom: number = 0;
}

interface ZIDXSearchCount {
    count: number;
    offset: number;

    [index: string]: any;
}

class ZIDXSearchFields {
    zidx_search_price_from: number = 0;
    zidx_search_price_to: number = 2000000000;
    zidx_search_bedrooms_from: number = 0;
    zidx_search_bedrooms_to: number = 100;
    zidx_search_bathrooms_from: number = 0;
    zidx_search_bathrooms_to: number = 100;
    zidx_search_sqft_from: number = 0;
    zidx_search_sqft_to: number = 1000000000;
    zidx_search_acreage_from: number = 0;
    zidx_search_acreage_to: number = 1000000000;
    zidx_search_year_built_from: number = 0;
    zidx_search_year_built_to: number = 2100000000;
    zidx_search_timestamp_from: number = 0;
    zidx_search_timestamp_to: number = 2100000000;
    zidx_search_sort: number = 1;
    zidx_offset: number = 0;
    zidx_search_list_status: string = "";
    zidx_search_type: string = "";
    zidx_search_sub_type: string = "";
    zidx_search_county: string = "";
    zidx_search_parking: string = "";
    zidx_search_frontage: string = "";
    zidx_search_style: string = "";
    zidx_search_view: string = "";
    zidx_search_status: string = "";
    zidx_search_condition: string = "";
    zidx_layout: number = LISTING_LAYOUT_LIST;
    zidx_search_city: string = "";
    zidx_search_coordinates: string = "";
    zidx_search_location: string = "";
    zidx_search_listing_table_id: string = "";
    zidx_search_listing_id: string = "";
    zidx_search_polygon: string = "";
    zidx_search_address: string = "";
    zidx_search_subdivision: string = "";
    zidx_search_postal_code: string = "";
    zidx_search_show_listings: string = "0";
    zidx_search_remarks: string = "";
    zidx_search_exclude_remarks: string = "";
    zidx_search_agent_sort: string = "";
    zidx_search_agent_name: string = "";
    zidx_search_office_sort: string = "";
    zidx_search_office_name: string = "";

    [key: string]: string | number;
}

interface Window {
    L: any;
    zidxSearchCriteria: any;
    zidxSearchCount: ZIDXSearchCount;
    zidxDisableSearch: any;
    ZIDXLoadGoogleMap: Function;
    zidxListingData: ZIDXListingData;
    zidxCheckForSearchFormChanges: Function;
    zidxGetListingURL: Function;
    zidxBase: ZIDXBase;
}

let zidxMap: any;
let searchIsEmbedded=true;
if(searchIsEmbedded){
    ZIDX.$(".zidxBodyElement").addClass("zidxEmbeddedBody");
}

class ZIDXBase {

    qs: string = "";
    googleMapURLLoaded = false;
    googleMapReady = false;
    lastPostObj = new ZIDXSearchFields();
    searchMorePopupOpen = false;
    mapLoaded = false;
    searchFormSetupOnce = false;
    skipMenuHitText = false;
    listingData = new ZIDXListingData();
    resetListingData = false;
    infiniteScroll: InfiniteScroll | null = null;
    arrListingSummaryFieldOrder = ["type", "id", "feedId", "price", "latitude", "longitude", "bedrooms", "bathrooms", "halfBaths", "sqft", "acreage", "address", "address2", "city", "state", "postalCode", "status", "listStatus", "photo", "createdDatetime", "showListings"];
    renderSaveButton = false;
    openInNewTab = false;
    useFreeTrial = true;
    searchDomain = "";

    nativeFieldLookup: any = {
        zidx_search_latitude: 'a',
        zidx_search_longitude: 'b',
        zidx_search_price: 'c',
        zidx_search_bedrooms: 'd',
        zidx_search_bathrooms: 'e',
        zidx_search_sqft: 'f',
        zidx_search_acreage: 'g',
        zidx_search_city: 'h',
        zidx_search_type: 'i',
        zidx_search_timestamp: 'j',
        zidx_search_state: 'k',
        zidx_search_status: 'l',
        zidx_search_agent_sort: 'S',
        zidx_search_agent_name: 'm',
        zidx_search_office_sort: 'T',
        zidx_search_office_name: 'n',
        zidx_search_region: 'o',
        zidx_search_address: 'p',
        zidx_search_remarks: 'q', // remarks
        zidx_search_exclude_remarks: 'r', // exclude
        zidx_search_county: 's', // county

        zidx_search_sub_type: 't', // propertysubtype
        zidx_search_frontage: 'u', // frontage
        zidx_search_view: 'v', // active
        zidx_search_parking: 'w', // parking
        zidx_search_style: 'x', // style
        zidx_search_condition: 'y', // condition
        zidx_search_list_status: 'z', // active

        zidx_search_subdivision: 'A', // string
        // zidx_search_agent_only: 'B', // 0,1
        // zidx_search_office_only: 'C', // 0,1
        zidx_search_city_only: 'D', // 0,1
        zidx_search_sort: 'E', // 0,1,2
        zidx_search_map: 'F', // 0 = hide map, 1 = show map
        zidx_offset: 'G', // 0
        zidx_search_year_built: 'J',
        zidx_limit: 'H', // 0
        zidx_search_postal_code: 'I', // 12345
        zidx_search_return_detail: 'K',
        zidx_search_address_only: 'L',
        zidx_search_count_only: 'M',
        zidx_layout: 'N',
        zidx_search_listing_id: 'O',
        zidx_search_listing_table_id: 'P',
        zidx_search_polygon: 'Q',
        zidx_search_show_listings: 'U'
    };
    formFieldLookupFromNative: any = {};
    // if(ZIDX.options.debug) console.log("formFieldLookupFromNative", formFieldLookupFromNative);
    nativeRangeFieldLookup: any = {
        zidx_search_price: 'c',
        zidx_search_bedrooms: 'd',
        zidx_search_bathrooms: 'e',
        zidx_search_year_built: 'J',
        zidx_search_sqft: 'f',
        zidx_search_acreage: 'g',
        zidx_search_timestamp: 'j'
    };
    defaultStruct: any = {
        zidx_search_list_status: "Active",
        zidx_search_sort: 1,
        zidx_search_price_from: 0,
        zidx_search_price_to: 2000000000,
        zidx_search_bedrooms_from: 0,
        zidx_search_bedrooms_to: 100,
        zidx_search_bathrooms_from: 0,
        zidx_search_bathrooms_to: 100,
        zidx_search_sqft_from: 0,
        zidx_search_sqft_to: 1000000000,
        zidx_search_acreage_from: 0,
        zidx_search_acreage_to: 1000000000,
        zidx_search_year_built_from: 0,
        zidx_search_year_built_to: 10000,
        zidx_search_timestamp_from: 0,
        zidx_search_timestamp_to: 2100000000,
        zidx_search_show_listings: "All"
    };
    constructor() {
        for (let i in this.nativeFieldLookup) {
            this.formFieldLookupFromNative[this.nativeFieldLookup[i]] = i;
        }
        window.ZIDXOptions.loadFunctions.push(() => {
            this.bindSaveButtons();
        });
    }
    bindSaveButtons(){
        ZIDX.$(".zidxSearchResultListingContainer, .zidxListingDetailSave").each(function (this: any) {
            let $this = ZIDX.$(this);
            let listingId = $this.attr("data-id");
            if (!listingId) {
                return;
            }
            let $i = $this.find('.zidxSearchResultSave');
            // console.log($i);
            let isSaved = SavedListingRepository.getInstance().isSaved(listingId);
            $i.attr('saved', isSaved);
            if (isSaved) {
                $i.removeClass("fa-heart-o");
                $i.addClass("fa-heart");
            } else {
                $i.removeClass("fa-heart");
                $i.addClass("fa-heart-o");
            }
        });
        ZIDX.$(".zidxSearchResultSave").off("mouseover").on("mouseover", this.onSearchResultMouseOver);
        ZIDX.$(".zidxSearchResultSave").off("mouseout").on("mouseout", this.onSearchResultMouseOut);
        // ZIDX.$(".zidxSearchResultListingContainer").on("click", this.onSearchResultClick)
        ZIDX.$(".zidxSearchResultSave").off("click").on("click", function(this:HTMLElement, e:ClickEvent){
            e.stopPropagation();
            e.preventDefault();
            let listingId = ZIDX.$(this).attr("data-id");
            if (!listingId) {
                alert("Unexpected error");
                return;
            }
            let saved = ZIDX.$(this).attr("saved") || 'false'
            if (saved == 'true') {
                SavedListingRepository.getInstance().remove(listingId);
                ZIDX.$(this).attr("saved", 'false');
                ZIDX.$(this).removeClass("fa-heart");
                ZIDX.$(this).addClass("fa-heart-o");
                if(ZIDX.$(this.parentNode!.parentNode!).hasClass("zidxSavedListingContainer")){
                    ZIDX.$(this.parentNode!.parentNode!).remove();
                }
            } else {
                SavedListingRepository.getInstance().save(listingId);
                ZIDX.$(this).attr("saved", 'true');
                ZIDX.$(this).removeClass("fa-heart-o");
                ZIDX.$(this).addClass("fa-heart");
            }
        });

    }
    setFieldFromLocalStorage(containerSelector:string, field:string, id:string){
        let val=localStorage.getItem("zidx."+field);
        if(val!=null){
            ZIDX.$("#"+id).val(val);
        }
        ZIDX.$(containerSelector+" #"+id).off("input").on("input", function(this:HTMLInputElement){
            let val=ZIDX.$(this).val();
            localStorage.setItem("zidx."+field, val);
        });
    }

    isFreeTrial(): boolean {
        return this.useFreeTrial && ZIDX.options.freeTrial
    }

    init(renderSaveButton: boolean, openInNewTab: boolean, useFreeTrial: boolean, loadCurrentSearchFromLocal: boolean) {
        this.renderSaveButton = renderSaveButton
        this.openInNewTab = openInNewTab
        this.useFreeTrial = useFreeTrial

        if (ZIDX.zIsTouchscreen()) {
            if (ZIDX.inIframe()) {
                // no cookies, need to break out if they click map
                ZIDX.$(".zidxSearchMapLink").each(function (this: HTMLAnchorElement) {
                    this.target = "_blank";
                });
                ZIDX.$(".zidxSearchMapLink.zidxActive").each(function () {
                    setTimeout(function () {
                        ZIDX.$(".zidxSearchListLink").trigger("click");
                    }, 10);
                });
            } else {
                ZIDX.$(".zidxSearchFullscreenLink").hide();
            }
        } else {
            ZIDX.$(".zidxSearchFullscreenLink").hide();
        }
        if (ZIDX.$("#zidx_searchhome_loaded").attr("data-loaded") == "0") {
            this.setupSearchForm(loadCurrentSearchFromLocal);
            ZIDX.$("#zidx_searchhome_loaded").attr("data-loaded", "1");
        }


        this.searchDomain = ZIDX.$(".zidxSearchRoot").attr("data-domain") || "";


    }


    onSearchResultMouseOver = function (this: any, e: MouseEvent) {
        const $target = ZIDX.$(e.target);
        if ($target.is(".zidxSearchResultSave") && $target.attr("saved") != 'true') {
            if ($target.attr("saved") != 'true') {
                $target.removeClass("fa-heart-o");
                $target.addClass("fa-heart");
            }
        }
    }


    onSearchResultMouseOut = function (this: any, e: MouseEvent) {
        const $target = ZIDX.$(e.target);
        if ($target.is(".zidxSearchResultSave") && $target.attr("saved") != 'true') {
            $target.removeClass("fa-heart");
            $target.addClass("fa-heart-o");
        }
    }

    // drawPageNav(count:number, perpage:number, offset:number){
    //     // if(ZIDX.options.debug) console.log("draw pagenav: "+count+":"+perpage+":"+offset);
    //     let options={
    //         id:"zidxSearchFormNav",
    //         count: (count),
    //         perpage: (perpage),
    //         offset: (offset),
    //         loadFunction: function(options:any){
    //             ZIDX.$("#zidx_offset").val(options.offset);
    //             // let link=this.urlAppend(ZIDX.options.prefix+"search", "offset="+options.offset);
    //
    //             let postObj=ZIDX.getFormDataByFormId("zidxSearchTopForm");
    //             postObj=dedupeSearchForm(postObj);
    //             // if(ZIDX.options.debug) console.log("drawPageNav", postObj);
    //             updateSearchCounts(postObj);
    //             doSearch(postObj);
    //
    //             ZIDX.jumpToId("zidxSearchResultsTopDiv");
    //         }
    //     }
    //     let p=new zPagination(options);
    // }

    doSearchCountCallback(s: string) {
        let arr = s.split("\t");
        if (arr.length == 2) {
            if (arr[0] == "1") {
                // success
                let count=parseInt(arr[1]);
                if(count>10000) {
                    ZIDX.$(".zidxSearchMorePopupResultCount").html("Thousands of ");
                }else{
                    ZIDX.$(".zidxSearchMorePopupResultCount").html(count);
                }
            } else {
                if (ZIDX.options.debug) console.log("Search Error:", arr[1]);
            }
        }
    }

    mapArrayToObject(arrData: string[], arrKeys: string[], obj: any) {
        for (let i = 0; i < arrData.length; i++) {
            switch (typeof obj[arrKeys[i]]) {
                case "number":
                    obj[arrKeys[i]] = parseFloat(arrData[i]);
                    break;
                default:
                    obj[arrKeys[i]] = arrData[i];
                    break;
            }
        }
        if (ZIDX.options.enableListingPhotos) {
            obj.photo = obj.photo.replace("http://", "/zimageproxy/");
        } else {
            obj.photo = "/images/test.jpg";
        }
    }

    urlEncode(value: string, escapeWith: string) {
        return value.replace(/[^a-z0-9]/gi, escapeWith);
    }

    getListingURL(ss: ZIDXListingSummary) {
        return ZIDX.getPrefixURL("/listing/" + this.urlEncode(ss.address + "-" + ss.city + "-" + ss.state + "-" + ss.postalCode + "-" + ss.feedId + "-" + ss.id, "-").replace(/--*/gi, "-")+"/");
    }

    // getListingPhoto(ss:ListingSummary, number:number) {
    //     if(ss.listing_photo_list != "" && listLen(ss.listing_photo_list, ",") >= arguments.number){
    //         if(ss.listing_photo_list CONTAINS "http://"){
    //             return request.domain&replace(listGetAt(ss.listing_photo_list, arguments.number, ","), "http://", "/zimageproxy/");
    //         }else{
    //             return request.domain&listGetAt(ss.listing_photo_list, arguments.number, ",");
    //         }
    //     }else{
    //         return request.domain&"/images/image-not-available.jpg";
    //     }
    // }
    htmlEditFormat(value: any): any {
        if (typeof value == "string") {
            return value.replace("\\", "\\\\").replace("\"", "&quot;").replace("<", "&lt;").replace(">", "&gt;");
        } else {
            return value;
        }
    }

    timestampToDateFormat(UNIX_timestamp: number) {
        let a = new Date(UNIX_timestamp * 1000);
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate();
        let hour = a.getHours();
        let min = a.getMinutes();
        let sec = a.getSeconds();
        return date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    }

    numberFormat(value: number): string {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
        return formatter.format(value);
    }

    renderListingThumbnailList(ss: ZIDXListingSummary, panel: InfiniteScrollPanel): string {
        const isSaved = SavedListingRepository.getInstance().isSaved(ss.id);
        let arrHTML = [];
        arrHTML.push("<div title=\"View Listing\" class=\"zidxSearchResultListingImage");
        if (!panel.visible) {
            arrHTML.push(" zidxImageNotLoaded\" data-image=\"" + ss.photo + "\"");
        }
        if (panel.visible) {
            arrHTML.push("\" style=\"background-image:url('" + ss.photo + "');\"");
        }
        arrHTML.push("><div class=\"zidxSearchResultListingPrice\">");
        if (this.renderSaveButton) {
            arrHTML.push(`
                    <div class=\"zidxSearchResultListingSaveButton\">
                        <i title=\"Save property\" data-id='${ss.id}' saved='${isSaved}' class=\"fa ${isSaved ? 'fa-heart' : 'fa-heart-o'} zidxSearchResultSave\" aria-hidden=\"true\"></i>
                    </div>
            `);
        }
        if (ss.price != 0) {
            arrHTML.push("$" + this.numberFormat(ss.price));
        }
        arrHTML.push("</div></div>\n" +
            "            <div class=\"zidxSearchResultListingTextContainer\">\n" +
            "            <div title=\"View Listing\">\n");
        // arrHTML.push(timestampToDateFormat(ss.createdDatetime)+"<br>");
        let specs = [];
        if (ss.bedrooms != 0) {
            specs.push(ss.bedrooms + " Beds &nbsp; ");
        }
        if (ss.bathrooms != 0) {
            specs.push(ss.bathrooms + " Baths &nbsp; ");
        }
        if (ss.halfBaths != 0) {
            specs.push(ss.halfBaths + " Half Baths &nbsp; ");
        }
        if (ss.sqft != 0) {
            specs.push(ss.sqft + " SQFT");
        }
        if (specs.length > 0) {
            arrHTML.push("<div class=\"zidxSearchResultListingSpecs\">" + specs.join("") + "</div>");
        }
        arrHTML.push(
            "                <div class=\"zidxSearchResultListingAddress\">\n" +
            "                " + ss.address + "&nbsp;<br>\n" +
            "            " + ss.city + ", " + ss.state + " " + ss.postalCode + "\n" +
            "            </div>\n" +
            "            <div class=\"zidxSearchResultListingType\">\n" +
            "                " + ss.type + "&nbsp;\n" +
            "            </div>\n" +
            "            <div class=\"zidxSearchResultListingStatus\">\n" +
            "                " + ss.listStatus + "&nbsp;\n" +
            "            </div>\n" +
            "            </div>\n" +
            // "            <a href=\""+link+"\" class=\"zidxSearchResultListingButton\">\n" +
            // "                View\n" +
            // "            </a>\n" +
            "            </div>");
        return arrHTML.join("");
    }

    renderListingThumbnailDetail(ss: ZIDXListingSummary, panel: InfiniteScrollPanel): string {
        let arrHTML = [];
        const isSaved = SavedListingRepository.getInstance().isSaved(ss.id);

        arrHTML.push("<div title=\"View Listing\" class=\"zidxSearchDetailImage");
        if (!panel.visible) {
            arrHTML.push(" zidxImageNotLoaded\" data-image=\"" + ss.photo + "\"");
        }
        if (panel.visible) {
            arrHTML.push("\" style=\"background-image:url('" + ss.photo + "');\"");
        }
        arrHTML.push("><span>" + this.htmlEditFormat("Photo of " + ss.address) + "</span></div>\n");

        arrHTML.push("<div class=\"zidxSearchDetailPrice\">");
        if (ss.price != 0) {
            arrHTML.push("$" + this.numberFormat(ss.price));
        }
        arrHTML.push("</div><div class=\"zidxSearchDetailSQFT\">");
        if (ss.sqft != 0) {
            arrHTML.push(ss.sqft + " SQFT");
        }
        arrHTML.push("</div><div class=\"zidxSearchDetailBeds\">");
        if (ss.bedrooms != 0) {
            arrHTML.push(ss.bedrooms + " Beds");
        }
        arrHTML.push("</div><div class=\"zidxSearchDetailBaths\">");
        if (ss.bathrooms != 0) {
            arrHTML.push(ss.bathrooms + " Baths");
        }
        if (ss.halfBaths != 0) {
            arrHTML.push("<br>" + ss.halfBaths + " Half Baths &nbsp; ");
        }
        arrHTML.push("</div>\n" +
            "            <div class=\"zidxSearchDetailType\">\n" +
            "            " + ss.type + "\n" +
            "        </div>\n" +
            "        <div class=\"zidxSearchDetailStatus\">\n" +
            "            " + ss.listStatus + "\n" +
            "        </div>\n" +
            "        <div class=\"zidxSearchDetailAddress\">\n" +
            "            " + ss.address + "<br>\n" +
            "        " + ss.city + ", " + ss.state + " " + ss.postalCode + "\n" +
            "        </div>");//\n" +
        // "        <div class=\"zidxSearchDetailButtons\">\n" +
        // "        <a href=\""+link+"\" class=\"zidxSearchResultListingButton\">\n" +
        // "            View Listing\n" +
        // "        </a>\n" +

        if (this.renderSaveButton) {
            arrHTML.push(`
                    <div class=\"zidxSearchDetailSaveButton\">
                        <i title=\"Save property\" data-id='${ss.id}' saved='${isSaved}' class=\"fa ${isSaved ? 'fa-heart' : 'fa-heart-o'} zidxSearchResultSave\" aria-hidden=\"true\"></i>
                    </div>
            `);
        }
        // "        </div>");
        return arrHTML.join("");
    }


    doSearchCallback(s: string, offset: number) {
        if (ZIDX.resetListingData) {
            this.listingData = new ZIDXListingData();
            this.listingDataOffsetsLoaded = {};
            this.listingDataOffsetsLoading = {};
            this.currentOffset = 0;
            ZIDX.$(".zidxSearchResultsGridContainer").scrollTop(0);
        }
        let arrLine = s.split("\n");
        let searchResultCount = arrLine.length;
        if (this.isFreeTrial() && searchResultCount > 20) {
            searchResultCount = Math.min(searchResultCount, 20);
        }
        let statusLine = arrLine[0].split("\t");
        if (statusLine[0] == "1") {
            this.listingData.totalListings = parseInt(statusLine[1]);
            if (this.isFreeTrial()) {
                this.listingData.totalListings = Math.min(this.listingData.totalListings, 20);
            }
            if(this.listingData.totalListings>10000) {
                ZIDX.$(".zidxSearchMorePopupResultCount").html("Thousands of ");
            }else{
                ZIDX.$(".zidxSearchMorePopupResultCount").html(this.listingData.totalListings);
            }
        } else {
            if (ZIDX.options.debug) console.log("Search status: fail. Error Message: " + statusLine[1]);
            return;
        }
        let storedListings = 0;
        if(searchResultCount<9){
            console.log("searchResultCount", searchResultCount);
            ZIDX.$(".zidxSearchMorePopup").hide();
        }
        for (let i = 1; i < searchResultCount; i++) {
            if (arrLine[i].trim().length == 0) continue;
            let row = arrLine[i].split("\t");
            let listingSummary = new ZIDXListingSummary();
            this.mapArrayToObject(row, this.arrListingSummaryFieldOrder, listingSummary);
            listingSummary.fixCase();
            this.listingData.listings[offset + (i - 1)] = listingSummary;
            storedListings++;
        }
        if (ZIDX.options.debug) console.log("Search status: success." +
            " Total Matched Listings: " + this.listingData.totalListings +
            " Stored Listings:" + storedListings +
            " Total Loaded Listings:" + this.listingData.listings.length);
        this.updateListingData();
        if (this.infiniteScroll != null) {
            this.infiniteScroll.destroyPanels();
        }
        this.displayListings();
    }

    parseSearchData() {
        let searchDataElement = document.getElementById("zidxSearchData")!;
        let s = searchDataElement.innerHTML;
        let arrLine = s.split("").reverse().join("").split("\n");
        let statusLine = arrLine[0].split("\t");
        if (statusLine[0] == "1") {
            this.listingData.totalListings = parseInt(statusLine[1]);
        } else {
            return;
        }
        for (let i = 1; i < arrLine.length; i++) {
            if (arrLine[i].trim().length == 0) continue;
            let row = arrLine[i].split("\t");
            let listingSummary = new ZIDXListingSummary();
            this.mapArrayToObject(row, this.arrListingSummaryFieldOrder, listingSummary);
            listingSummary.fixCase();
            this.listingData.listings.push(listingSummary);
        }
        this.updateListingData();
    }

    updateListingData() {
        this.listingData.locationCount = 0;
        this.listingData.averageLatitude = 0;
        this.listingData.averageLongitude = 0;
        this.listingData.minLongitude = 0;
        this.listingData.maxLongitude = 0;
        this.listingData.minLatitude = 0;
        this.listingData.maxLatitude = 0;
        for (let i = 0; i < this.listingData.listings.length; i++) {
            let listingSummary = this.listingData.listings[i];
            if (typeof listingSummary == "undefined") {
                continue;
            }
            if (listingSummary.latitude != 0) {
                if (this.listingData.minLatitude == 0) {
                    this.listingData.minLatitude = listingSummary.latitude;
                } else {
                    this.listingData.minLatitude = Math.min(this.listingData.minLatitude, listingSummary.latitude);
                }
                if (this.listingData.minLongitude == 0) {
                    this.listingData.minLongitude = listingSummary.longitude;
                } else {
                    this.listingData.minLongitude = Math.min(this.listingData.minLongitude, listingSummary.longitude);
                }
                this.listingData.maxLatitude = Math.max(this.listingData.maxLatitude, listingSummary.latitude);
                this.listingData.maxLongitude = Math.max(this.listingData.maxLongitude, listingSummary.longitude);
                this.listingData.averageLatitude += listingSummary.latitude;
                this.listingData.averageLongitude += listingSummary.longitude;
                this.listingData.locationCount++;
            }
        }
        if (this.listingData.locationCount != 0) {
            this.listingData.averageLatitude /= this.listingData.locationCount;
            this.listingData.averageLongitude /= this.listingData.locationCount;
        }
    }

    displayListings() {
        if (this.infiniteScroll != null) {
            this.infiniteScroll.options.count = this.listingData.totalListings;
            this.infiniteScroll.update();
        }
        ZIDX.$(".zidxSearchResultsGridContainer").trigger("wheel");
        // drawPageNav(this.listingData.totalListings, this.listingData.perPage, this.lastPostObj.zidx_offset);
        if (ZIDX.$(".zidxSearchMapLink").hasClass("zidxActive")) {
            this.loadMap(function () {
            });
        }

    }

    currentOffset: number = 0;

    doSearchCallbackError(r: any) {
        //alert("Sorry, please try again later or check your Internet connection.");
        delete this.listingDataOffsetsLoading[this.currentOffset];
    }

    doSearchCountCallbackError(r: any) {
        //alert("Sorry, please try again later or check your Internet connection.");
    }

    buildNativeSearch(ss: ZIDXSearchFields): string {
        let arrQS: string[] = [];
        let debugStruct: any = {};
        for (let field in this.nativeRangeFieldLookup) {
            if (!this.nativeRangeFieldLookup.hasOwnProperty(field)) continue;
            let fieldChar = this.nativeRangeFieldLookup[field];
            let from = ss[field + "_from"];
            let to = ss[field + "_to"];
            if (typeof ss[field + "_from"] == "undefined") {
                from = this.defaultStruct[field + "_from"];
            }
            if (typeof ss[field + "_to"] == "undefined") {
                to = this.defaultStruct[field + "_to"];
            }
            let hasTo = false;
            if (from == this.defaultStruct[field + "_from"] && to == this.defaultStruct[field + "_to"]) {
                // if(ZIDX.options.debug) console.log("skipping default", from," == ",defaultStruct[field+"_from"], field+"_from");
                continue;
            }
            if (to == 0) {
                to = this.defaultStruct[field + "_to"];
            }
            debugStruct[field] = {from: from, to: to};
            arrQS.push("|" + fieldChar + from + "," + to);
        }
        for (let field in this.nativeFieldLookup) {
            if (!this.nativeFieldLookup.hasOwnProperty(field)) continue;
            let fieldChar = this.nativeFieldLookup[field];
            let v = ss[field];
            // if(ZIDX.options.debug) console.log("nativeField:", field, fieldChar, ss);
            if (typeof v == "number" || (typeof v == "string" && v != "")) {
                debugStruct[field] = v;
                if (field == "zidx_search_listing_id") {
                    let arrId = String(v).toLowerCase().split(",");
                    let arrListingId: string[] = [];
                    let arrListingTableId: string[] = [];
                    let arrListingIdTogether: string[] = [];
                    for (let n2 = 0; n2 < arrId.length; n2++) {
                        let id = arrId[n2];
                        let arrChar = [];
                        let arrInt = [];
                        for (let n = 0; n < id.length; n++) {
                            if (isNaN(parseInt(id.charAt(n)))) {
                                let charCode = id.toLowerCase().charCodeAt(n);
                                if (charCode >= 97 && charCode <= 122) {
                                    arrChar.push(id.charAt(n).toLowerCase());
                                }
                            } else {
                                arrInt.push(id.charAt(n));
                            }
                        }
                        if (arrInt.length > 0) {
                            arrListingId.push(arrInt.join(""));
                            arrListingTableId.push(arrChar.join(""));
                            arrListingIdTogether.push(arrChar.join("") + arrInt.join(""));
                        }
                    }
                    ZIDX.$("#zidx_search_listing_id").val(arrListingIdTogether.join(",").toUpperCase());
                    arrQS.push("|O" + arrListingId.join(",") + "|P" + arrListingTableId.join(","));
                } else {
                    // lower only some of them
                    if (field == "zidx_search_address" || field == "zidx_search_subdivision" || field == "zidx_search_remarks" || field == "zidx_search_exclude_remarks" || field == "zidx_search_agent_sort" || field == "zidx_search_agent_name" || field == "zidx_search_office_sort" || field == "zidx_search_office_name" || field == "zidx_search_postal_code") {
                        arrQS.push("|" + fieldChar + String(v).toLowerCase());
                    } else {
                        arrQS.push("|" + fieldChar + v);
                    }
                }
            }
        }
        // to see in plain english:
        if (ZIDX.options.debug) console.log("debugStruct", debugStruct);
        if (ZIDX.options.debug) console.log("qs", arrQS.join(""));
        return arrQS.join("");
    }

    setCheckboxValues(fieldName: string, values: string[]) {
        ZIDX.$("input[name='" + fieldName + "'], input[name='" + fieldName.replace("zidx_", "zidx_more_") + "']").each(function (this: HTMLInputElement) {
            for (let i = 0; i < values.length; i++) {
                let value = values[i];
                if (this.value == value) {
                    this.checked = true;
                    return;
                }
            }
        });
    }

    static firstLetterCaps(s: string): string {
        if(s.length==0) return s;
        return s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    decodeNativeSearch(qs: string) {
        let arr = qs.split("|");
        let checkboxGroups: any = {
            "zidx_search_type": true,
            "zidx_search_sub_type": true,
            "zidx_search_style": true,
            "zidx_search_frontage": true,
            "zidx_search_view": true,
            "zidx_search_parking": true,
            "zidx_search_condition": true,
            "zidx_search_list_status": true,
            "zidx_search_status": true,
            "zidx_search_county": true,
            "zidx_search_region": true,
        }
        let regularInputs: any = {
            "zidx_search_latitude": true,
            "zidx_search_longitude": true,
            "zidx_search_polygon": true,
            "zidx_search_listing_id": true,
            "zidx_search_address": true,
            "zidx_search_subdivision": true,
            "zidx_search_postal_code": true,
            "zidx_search_remarks": true,
            "zidx_search_exclude_remarks": true,
            "zidx_search_agent_sort": true,
            "zidx_search_agent_name": true,
            "zidx_search_office_sort": true,
            "zidx_search_office_name": true,
        };
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].length < 2) {
                continue; // invalid format
            }
            let fieldChar = arr[i].substr(0, 1);
            let formField = this.formFieldLookupFromNative[fieldChar];
            if (typeof formField == "undefined") {
                if (ZIDX.options.debug) console.log("decodeNativeSearch error, fieldChar is invalid: ", fieldChar);
            }
            let values = decodeURIComponent(arr[i].substr(1)).replace("+", " ").split(",");
            let $field = ZIDX.$("#" + formField);
            let $moreField = ZIDX.$("#" + formField.replace("zidx_", "zidx_more_"));
            if (formField == "zidx_search_listing_id") {
                // merge with table id
                let tableIds = "";
                for (let i2 = 0; i2 < arr.length; i2++) {
                    if (arr[i2].substring(0, 1) == "P") {
                        tableIds = arr[i2].substring(1);
                    }
                }
                let arrListingTableId = decodeURIComponent(tableIds).replace("+", " ").split(",");
                for (let n = 0; n < values.length; n++) {
                    values[n] = arrListingTableId[n].toUpperCase() + values[n];
                }
                console.log(values, arrListingTableId);
                ZIDX.$("#" + formField).val(values.join(","));
            } else if (typeof checkboxGroups[formField] != "undefined") {
                this.setCheckboxValues(formField, values);
            } else if (typeof regularInputs[formField] != "undefined") {
                ZIDX.$("#" + formField).val(values.join(","));
            } else {
                if (typeof this.nativeRangeFieldLookup[formField] != "undefined") {
                    // from
                    let $fromField = ZIDX.$("#" + formField + "_from");
                    let $toField = ZIDX.$("#" + formField + "_to");
                    let $fromMoreField = ZIDX.$("#" + formField.replace("zidx_", "zidx_more_") + "_from");
                    let $toMoreField = ZIDX.$("#" + formField.replace("zidx_", "zidx_more_") + "_to");
                    if (values.length >= 1) {
                        if ($fromField.length > 0) {
                            $fromField.val(values[0]);
                            if ($fromField[0].selectedIndex == -1) {
                                $fromField[0].selectedIndex = 0;
                            }
                        }
                        if ($fromMoreField.length > 0) {
                            $fromMoreField.val(values[0]);
                            if ($fromMoreField[0].selectedIndex == -1) {
                                $fromMoreField[0].selectedIndex = 0;
                            }
                        }
                    }
                    if (values.length == 2) {
                        if ($toField.length > 0) {
                            $toField.val(values[1]);
                            if ($toField[0].selectedIndex == -1) {
                                $toField[0].selectedIndex = 0;
                            }
                        }
                        if ($toMoreField.length > 0) {
                            $toMoreField.val(values[1]);
                            if ($toMoreField[0].selectedIndex == -1) {
                                $toMoreField[0].selectedIndex = 0;
                            }
                        }
                    }
                } else {
                    if (values.length >= 1) {
                        $field.val(values[0]);
                        $moreField.val(values[0]);
                    } else {
                        $field.val(values);
                        $moreField.val(values);
                    }
                }
            }
            // if(ZIDX.options.debug) console.log("formField: "+formField, "values:", values);
        }
        ZIDX.$("#zidx_offset").val(0);

    }

    doSearch(postObj: ZIDXSearchFields) {
        if (typeof window.zidxDisableSearch != "undefined" && parseInt(window.zidxDisableSearch) == 1) {
            return;
        }
        if (postObj.zidx_layout == LISTING_LAYOUT_MAP) {
            postObj.mapWidth = 100;
            postObj.mapHeight = 100;
        }
        let qs = this.buildNativeSearch(postObj);
        this.qs = qs;
        if (ZIDX.cookiesEnabled()) {
            ZIDX.setCookie({
                key: "zidxCurrentSearch",
                value: qs,
                futureSeconds: 60 * 60 * 30,
                enableSubdomains: false
            });
        } else {
            localStorage.setItem("zidxCurrentSearch", qs);
        }
        let self = this;
        let searchDomain = self.searchDomain;
        // This allows non-c++ devs to do searches against live server without setup of c++ app
        if (ZIDX.isTestServer() && ZIDX.options.enableRemoteSearch) {
            searchDomain = "https://peak1.peak.test.zsite.info";
        }
        if (this.searchMorePopupOpen) {
            // if(ZIDX.options.debug) console.log("doSearch count only");
            let obj = {
                id: "ajaxSearch",
                method: "get",
                postObj: {},
                ignoreOldRequests: false,
                callback: function (r: string) {
                    self.doSearchCountCallback(r);
                },
                errorCallback: function (r: string) {
                    self.doSearchCountCallbackError(r);
                },
                url: ZIDX.options.siteDomain + "/search?qs=" + escape("|M1" + qs)
            };
            ZIDX.ajaxRequest(obj);
        } else {
            // if(ZIDX.options.debug) console.log("doSearch count and data");
            let obj = {
                id: "ajaxSearch",
                method: "get",
                postObj: {},
                ignoreOldRequests: false,
                callback: function (r: string) {
                    self.listingDataOffsetsLoaded[postObj.zidx_offset] = true;
                    self.doSearchCallback(r, postObj.zidx_offset);
                },
                errorCallback: function (r: string) {
                    delete self.listingDataOffsetsLoading[postObj.zidx_offset];
                    self.doSearchCallbackError(r);
                },
                url: ZIDX.options.siteDomain + "/search?qs=" + escape(qs)
                // url: searchDomain + ZIDX.options.prefix + "search?qs=" + escape(qs)
            };
            ZIDX.ajaxRequest(obj);
        }
    }

    closeMorePopup() {
        if (this.searchMorePopupOpen) {
            this.searchMorePopupOpen = false;
            ZIDX.$("body").removeClass("zidxMobileMenuDisableScroll");
            // if() {
            //     ZIDX.$("body").css("overflow", "auto");
            // }
            ZIDX.$(".zidxSearchMorePopup").hide();
            this.updateSearchCounts(this.lastPostObj);
            console.log("checkForSearchFormChanges 2");
            this.checkForSearchFormChanges(true, true);
        }
    }

    dedupeSearchForm(postObj: any): ZIDXSearchFields {
        let searchFields = new ZIDXSearchFields();
        if (typeof postObj.zidx_more_search_city == "undefined") {
            return searchFields;
        }
        searchFields.zidx_offset = parseInt(postObj.zidx_offset);
        if (isNaN(searchFields.zidx_offset)) searchFields.zidx_offset = 0;
        if (typeof postObj.zidx_more_search_list_status != "undefined") {
            searchFields.zidx_search_list_status = postObj.zidx_more_search_list_status;
        }
        if (typeof postObj.zidx_more_search_type != "undefined") {
            searchFields.zidx_search_type = postObj.zidx_more_search_type;
        }
        if (typeof postObj.zidx_more_search_sub_type != "undefined") {
            searchFields.zidx_search_sub_type = postObj.zidx_more_search_sub_type;
        }
        if (typeof postObj.zidx_more_search_county != "undefined") {
            searchFields.zidx_search_county = postObj.zidx_more_search_county;
        }
        if (typeof postObj.zidx_more_search_parking != "undefined") {
            searchFields.zidx_search_parking = postObj.zidx_more_search_parking;
        }
        if (typeof postObj.zidx_more_search_frontage != "undefined") {
            searchFields.zidx_search_frontage = postObj.zidx_more_search_frontage;
        }
        if (typeof postObj.zidx_more_search_style != "undefined") {
            searchFields.zidx_search_style = postObj.zidx_more_search_style;
        }
        if (typeof postObj.zidx_more_search_view != "undefined") {
            searchFields.zidx_search_view = postObj.zidx_more_search_view;
        }
        if (typeof postObj.zidx_more_search_status != "undefined") {
            searchFields.zidx_search_status = postObj.zidx_more_search_status;
        }
        if (typeof postObj.zidx_more_search_condition != "undefined") {
            searchFields.zidx_search_condition = postObj.zidx_more_search_condition;
        }
        searchFields.zidx_search_polygon = postObj.zidx_search_polygon;
        searchFields.zidx_search_sort = parseInt(postObj.zidx_search_sort);
        if (isNaN(searchFields.zidx_search_sort)) searchFields.zidx_search_sort = 0;
        searchFields.zidx_layout = postObj.zidx_layout;
        searchFields.zidx_search_city = postObj.zidx_more_search_city;
        searchFields.zidx_search_latitude = postObj.zidx_search_latitude;
        searchFields.zidx_search_longitude = postObj.zidx_search_longitude;
        if (typeof postObj.zidx_search_location != "undefined") {
            searchFields.zidx_search_location = postObj.zidx_search_location;
        }
        if (postObj.zidx_search_price_from != "") {
            searchFields.zidx_search_price_from = parseInt(postObj.zidx_search_price_from);
        }
        if (postObj.zidx_search_price_to != "") {
            searchFields.zidx_search_price_to = parseInt(postObj.zidx_search_price_to);
        }
        if (postObj.zidx_search_bedrooms_from != "") {
            searchFields.zidx_search_bedrooms_from = parseInt(postObj.zidx_search_bedrooms_from);
        }
        if (postObj.zidx_search_bedrooms_to != "") {
            searchFields.zidx_search_bedrooms_to = parseInt(postObj.zidx_search_bedrooms_to);
        }
        if (postObj.zidx_search_bathrooms_from != "") {
            searchFields.zidx_search_bathrooms_from = parseInt(postObj.zidx_search_bathrooms_from);
        }
        if (postObj.zidx_search_bathrooms_to != "") {
            searchFields.zidx_search_bathrooms_to = parseInt(postObj.zidx_search_bathrooms_to);
        }
        if (postObj.zidx_more_search_acreage_from != "") {
            searchFields.zidx_search_acreage_from = parseInt(postObj.zidx_more_search_acreage_from);
        }
        if (postObj.zidx_more_search_acreage_to != "") {
            searchFields.zidx_search_acreage_to = parseInt(postObj.zidx_more_search_acreage_to);
        }
        if (postObj.zidx_more_search_year_built_from != "") {
            searchFields.zidx_search_year_built_from = parseInt(postObj.zidx_more_search_year_built_from);
        }
        if (postObj.zidx_more_search_year_built_to != "") {
            searchFields.zidx_search_year_built_to = parseInt(postObj.zidx_more_search_year_built_to);
        }
        if (postObj.zidx_more_search_sqft_from != "") {
            searchFields.zidx_search_sqft_from = parseInt(postObj.zidx_more_search_sqft_from);
        }
        if (postObj.zidx_more_search_sqft_to != "") {
            searchFields.zidx_search_sqft_to = parseInt(postObj.zidx_more_search_sqft_to);
        }
        if(postObj.zidx_search_show_listings != "undefined") {
            searchFields.zidx_search_show_listings=postObj.zidx_search_show_listings;
        }
        this.limitTo32Values("zidx_search_listing_id", searchFields, postObj);
        this.limitTo32Values("zidx_search_address", searchFields, postObj);
        this.limitTo32Values("zidx_search_subdivision", searchFields, postObj);
        this.limitTo32Values("zidx_search_postal_code", searchFields, postObj);
        this.limitTo32Values("zidx_search_remarks", searchFields, postObj);
        this.limitTo32Values("zidx_search_exclude_remarks", searchFields, postObj);
        this.limitTo32Values("zidx_search_agent_sort", searchFields, postObj);
        this.limitTo32Values("zidx_search_agent_name", searchFields, postObj);
        this.limitTo32Values("zidx_search_office_sort", searchFields, postObj);
        this.limitTo32Values("zidx_search_office_name", searchFields, postObj);
        if (typeof postObj.zidx_search_listing_id != "undefined") {
            searchFields.zidx_search_listing_id = postObj.zidx_search_listing_id;
        }
        if (typeof postObj.zidx_search_listing_table_id != "undefined") {
            searchFields.zidx_search_listing_table_id = postObj.zidx_search_listing_table_id;
        }
        // if(ZIDX.options.debug) console.log("searchFields", searchFields);
        return searchFields;
    }

    limitTo32Values(fieldName: string, searchFields: ZIDXSearchFields, postObj: any) {
        if (typeof postObj[fieldName] == "undefined") {
            return;
        }
        postObj[fieldName] = postObj[fieldName].toLowerCase();
        let a = postObj[fieldName].split(",");
        if (a.length > 32) {
            alert("There is a limit of 32 values.");
            let newValue = a.splice(0, 32).join(",");
            postObj[fieldName] = newValue;
            searchFields[fieldName] = newValue;
            ZIDX.$("#" + fieldName).val(newValue);
        } else {
            searchFields[fieldName] = postObj[fieldName];
        }
    }

    doSearchTimeout = 0;

    checkForSearchFormChanges(forceSearch: boolean, resetListingData: boolean) {
        if (document.getElementById("zidxSearchTopForm") == null) {
            return;
        }
        let postObjTemp = ZIDX.getFormDataByFormId("zidxSearchTopForm");
        let postObj = this.dedupeSearchForm(postObjTemp);
        // console.log("second post", postObj);
        this.updateSearchCounts(postObj);

        let formChanged = false;
        if (forceSearch) {
            formChanged = true;
        } else {
            for (let i in postObj) {
                if (!postObj.hasOwnProperty(i)) {
                    continue;
                }
                if (typeof this.lastPostObj[i] == "undefined" || this.lastPostObj[i] != postObj[i]) {
                    if (ZIDX.options.debug) console.log(i + " changed", this.lastPostObj[i], postObj[i]);
                    formChanged = true;
                }
            }
        }
        if (formChanged) {
            ZIDX.resetListingData = resetListingData;
            if (ZIDX.options.debug) console.log("this.checkForSearchFormChanges", postObj, "resetListingData:", resetListingData);
            this.updateSearchFormLabels(postObj);
            if (resetListingData) {
                // we can't clear this timeout when infinite scrolling, but we can when the user has changed the search criteria
                clearTimeout(this.doSearchTimeout);
            }
            let self = this;
            this.doSearchTimeout = setTimeout(function () {
                self.doSearch(postObj);
            }, 250);
            this.lastPostObj = postObj;
        }
    }

    getLabel(defaultLabel: string, from: string, to: string, suffix: string): string {
        let label = defaultLabel;
        if (from == "$0" || from == "From") {
            // default
            if (to == "Any" || to == "To") {
            } else {
                label = "Up to " + to;
                if (suffix.length > 0) {
                    label += suffix;
                }
            }
        } else {
            if (to == "Any" || to == "To") {
                label = from + "+";
            } else {
                label = from + "-" + to;
            }
            if (suffix.length > 0) {
                label += suffix;
            }
        }
        return label;
    }

    updateSearchFormLabels(postObj: ZIDXSearchFields) {
        let cityElement = <HTMLSelectElement>document.getElementById("zidx_search_city")!;
        let label = "City";
        if (cityElement.selectedIndex > 0) {
            label = cityElement.options[cityElement.selectedIndex].text;
        }
        ZIDX.$("#zidxSearchCityContainer .zidxSearchLabel").html(label);
        let priceFromElement = <HTMLSelectElement>document.getElementById("zidx_search_price_from")!;
        let priceFrom = priceFromElement.options[priceFromElement.selectedIndex].text;
        let priceToElement = <HTMLSelectElement>document.getElementById("zidx_search_price_to")!;
        let priceTo = priceToElement.options[priceToElement.selectedIndex].text;
        ZIDX.$("#zidxSearchPriceContainer .zidxSearchLabel").html(this.getLabel("Price", priceFrom, priceTo, ""));

        let bedroomsFromElement = <HTMLSelectElement>document.getElementById("zidx_search_bedrooms_from")!;
        let bedroomsFrom = bedroomsFromElement.options[bedroomsFromElement.selectedIndex].text;
        let bedroomsToElement = <HTMLSelectElement>document.getElementById("zidx_search_bedrooms_to")!;
        let bedroomsTo = bedroomsToElement.options[bedroomsToElement.selectedIndex].text;
        ZIDX.$("#zidxSearchBedroomsContainer .zidxSearchLabel").html(this.getLabel("Beds", bedroomsFrom, bedroomsTo, " Beds"));

        let bathroomsFromElement = <HTMLSelectElement>document.getElementById("zidx_search_bathrooms_from")!;
        let bathroomsFrom = bathroomsFromElement.options[bathroomsFromElement.selectedIndex].text;
        let bathroomsToElement = <HTMLSelectElement>document.getElementById("zidx_search_bathrooms_to")!;
        let bathroomsTo = bathroomsToElement.options[bathroomsToElement.selectedIndex].text;
        ZIDX.$("#zidxSearchBathroomsContainer .zidxSearchLabel").html(this.getLabel("Baths", bathroomsFrom, bathroomsTo, " Baths"));

        // do the ones in more that are checkbox groups as well - but hide when group is open

        label = this.updateLabelValues("Type", "zidxPopupType");
        ZIDX.$("#zidxSearchTypeContainer .zidxSearchLabel").html(label);

        this.updateLabelValues("Type", "zidxSearchMoreType");
        this.updateLabelValues("Frontage", "zidxSearchMoreFrontage");
        this.updateLabelValues("Sub Type", "zidxSearchMoreSubType");
        this.updateLabelValues("County", "zidxSearchMoreCounty");
        this.updateLabelValues("Region", "zidxSearchMoreRegion");
        this.updateLabelValues("View", "zidxSearchMoreView");
        this.updateLabelValues("List Status", "zidxSearchMoreListStatus");
        this.updateLabelValues("Style", "zidxSearchMoreStyle");
        this.updateLabelValues("Parking", "zidxSearchMoreParking");
        this.updateLabelValues("Status", "zidxSearchMoreStatus");
        this.updateLabelValues("Condition", "zidxSearchMoreCondition");
        this.updateLabelValues("Frontage", "zidxSearchMoreFrontage");
        this.updateLabelValues("Show Listings", "zidxSearchMoreShowListings");
    }

    updateLabelValues(defaultLabel: string, className: string): string {
        let label = defaultLabel;
        let arrLabel: string[] = [];
        ZIDX.$("." + className + " input").each(function (this: HTMLInputElement) {
            if (this.checked) {
                arrLabel.push(ZIDX.$('label[for="' + this.id + '"]').text());
            }
        });
        if (arrLabel.length > 0) {
            label = arrLabel.join(", ");
        }
        if (label == defaultLabel) {
            ZIDX.$("." + className + " .zidxSearchCheckboxValues").html("");
        } else {
            ZIDX.$("." + className + " .zidxSearchCheckboxValues").html(label);
        }
        return label;
    }

    getLayoutString(id: number) {
        switch (id) {
            case LISTING_LAYOUT_LIST:
                return "zidxList";
            case LISTING_LAYOUT_DETAIL:
                return "zidxDetail";
            case LISTING_LAYOUT_MAP:
                return "zidxMap";
            default:
                if (ZIDX.options.debug) console.log("Invalid layout:" + id);
                break;
        }
    }

    listingDataOffsetsLoaded: any = {}; // TODO: Critical need to empty this whenever the search changes other then offset
    listingDataOffsetsLoading: any = {}; // TODO: Critical need to empty this whenever the search changes other then offset

    listingPanelRenderCallback(panel: InfiniteScrollPanel) {
        this.currentOffset = Math.floor(panel.offset / 200) * 200;
        if (this.listingData.listings.length == 0) {
            return;
        } else if (this.currentOffset != 0 && typeof this.listingDataOffsetsLoaded[this.currentOffset] == "undefined") {
            // do search
            if (typeof this.listingDataOffsetsLoading[this.currentOffset] != "undefined") {
                // if(ZIDX.options.debug) console.log("waiting for load to complete");
                // console.log("waiting for load to complete:", this.currentOffset);
                return;
            }
            // console.log("listings data length:", this.listingData.listings.length, panel.x, panel.y, panel.offset, this.listingData.listings);
            // console.log("listingPanelRenderCallback must doSearch for offset :"+this.currentOffset);
            ZIDX.$("#zidx_offset").val(this.currentOffset);
            this.listingDataOffsetsLoading[this.currentOffset] = true;
            // if(ZIDX.options.debug) console.log("Searching with new offset:", ZIDX.$("#zidx_offset").val(), "total loading listings:", this.listingData.listings.length, " this.listingData.totalListings:", this.listingData.totalListings);
            console.log("checkForSearchFormChanges 1");
            this.checkForSearchFormChanges(true, false);

            // calculate the current offset mod 200 based on number of panel columns being displayed and the current count

            // if (this.lastPostObj.zidx_offset + 200 < this.listingData.totalListings) {
            //     // if (this.waitUntilLoaded) {
            //     //     // if(ZIDX.options.debug) console.log("waiting for load to complete");
            //     //     return;
            //     // }
            //     ZIDX.$("#zidx_offset").val(this.lastPostObj.zidx_offset + 200);
            //     // if(ZIDX.options.debug) console.log("Searching with new offset:", ZIDX.$("#zidx_offset").val(), "total loading listings:", this.listingData.listings.length, " this.listingData.totalListings:", this.listingData.totalListings);
            //     // this.waitUntilLoaded = true;
            //     this.checkForSearchFormChanges(true, false);
            // }
            return;
        }
        // if(ZIDX.options.debug) console.log("render panel #", panel.offset);
        let ss = this.listingData.listings[panel.offset];
        // if(ZIDX.options.debug) console.log("listing:", ss);
        const link = this.getListingURL(ss);
        panel.element.setAttribute('data-id', ss.id);
        panel.element.href=this.searchDomain + link;
        if (window.zidxBase.openInNewTab) {
            panel.element.target="_blank";
            panel.element.rel="noopener noreferrer";
        }
        // TODO: need to make thumbnail link work another way, maybe absolute position instead of nested
        // ZIDX.$(panel.element).on('click', this.onSearchResultClick)
        ZIDX.$(panel.element).on('mouseover', this.onSearchResultMouseOver)
        ZIDX.$(panel.element).on('mouseout', this.onSearchResultMouseOut)
        let content;
        if (this.lastPostObj.zidx_layout == LISTING_LAYOUT_DETAIL) {
            content = this.renderListingThumbnailDetail(ss, panel);
        } else {
            content = this.renderListingThumbnailList(ss, panel);
        }
        panel.render(content);
        panel.rendered = true;
    }

    listingPanelHeightCallback(position: DOMRect): number {
        let textPosition = ZIDX.$(".zidxSearchResultListingTextContainer")[0].getBoundingClientRect();
        let imagePosition = ZIDX.$("#zidxPanelDimensionTest .zidxSearchResultListingImage")[0].getBoundingClientRect();
        let detailImagePosition = ZIDX.$("#zidxPanelDimensionTest .zidxSearchDetailImage")[0].getBoundingClientRect();
        if (this.lastPostObj.zidx_layout == LISTING_LAYOUT_DETAIL) {
            if (ZIDX.windowSize.width < 700) {
                return ((imagePosition.width * .56) + textPosition.height);
            } else if (ZIDX.windowSize.width < 992) {
                return ((imagePosition.width * .56) + textPosition.height);
            } else {
                return detailImagePosition.height + 30;
            }
        } else {
            if (ZIDX.windowSize.width < 700) {
                return ((imagePosition.width * .56) + textPosition.height);
            } else {
                return ((imagePosition.width * .56) + textPosition.height);
            }
        }
    }

    setupListingSearchInfiniteScroll() {

        let containerScrollerElement = ZIDX.$(".zidxSearchResultsGridContainer")[0];
        let containerElement = ZIDX.$(".zidxListingScrollContainer")[0];
        let footerElement = ZIDX.$(".zidxPanelFooter")[0];
        let options = new InfiniteScrollOptions(containerScrollerElement, containerElement, footerElement, this, "listingPanelHeightCallback", "listingPanelRenderCallback", searchIsEmbedded);
        options.debug = false;
        options.count = parseInt(ZIDX.$(".zidxSearchMorePopupResultCount")[0].innerHTML);
        // if(ZIDX.options.debug) console.log("infinite scroller loading with count:"+options.count);
        options.panelClass = "zidxSearchResultListingContainer";
        let self = this;
        // setTimeout(function(){
        self.infiniteScroll = new InfiniteScroll(options);
        // },1000);
    }

    firstSearchLoaded = false;

    loadCurrentSearchCriteria(loadCurrentSearchFromLocal: boolean) {
        if (typeof window.zidxSearchCriteria == "undefined") {
            return;
        }
        this.parseSearchData();

        // if(ZIDX.options.debug) console.log("before loaded search:", window.zidxSearchCriteria);
        let currentSearch = ZIDX.$("#zidx_current_search").val();
        console.log("loadCurrentSearchCriteria");
        if (loadCurrentSearchFromLocal) {
            if (currentSearch != "") {
                this.firstSearchLoaded = true;
                ZIDX.setCookie({
                    key: "zidxCurrentSearch",
                    value: currentSearch,
                    futureSeconds: 60 * 60 * 30,
                    enableSubdomains: false
                });
            } else if (ZIDX.cookiesEnabled()) {
                currentSearch = ZIDX.getCookie("zidxCurrentSearch");
            } else {
                let tempString = localStorage.getItem("zidxCurrentSearch");
                if (tempString != null) {
                    let newString = tempString;
                    let self = this;
                    // this fixes ios to preserve the search criteria on refresh.
                    if (!this.firstSearchLoaded && window.location.href.indexOf("zidxCurrentSearch=") == -1) {
                        setTimeout(function () {
                            self.firstSearchLoaded = true;
                            let arrLink = window.location.href.split("#");
                            let arrLink2 = arrLink[0].split("?");
                            if (arrLink[0].indexOf("?") != -1) {
                                window.location.href = ZIDX.getPrefixURL("/?" + arrLink2[1] + "&zidxCurrentSearch=" + escape(newString));
                            } else {
                                window.location.href = ZIDX.getPrefixURL("/?zidxCurrentSearch=" + escape(newString));
                            }
                        }, 10);
                        return;
                    }
                    currentSearch = tempString;
                }
            }
        }

        if (ZIDX.options.debug) console.log("zidxCurrentSearch:", currentSearch);
        try {
            this.decodeNativeSearch(currentSearch);
        }catch(e){

            if (ZIDX.cookiesEnabled()) {
                ZIDX.setCookie({
                    key: "zidxCurrentSearch",
                    value: "",
                    futureSeconds: 60 * 60 * 30,
                    enableSubdomains: false
                });
            } else {
                localStorage.setItem("zidxCurrentSearch", "");
            }
            this.loadCurrentSearchCriteria(false);
            return;
        }
        this.qs = currentSearch;
        let sortField = ZIDX.$("#zidx_search_sort")[0];
        if (sortField.value == "") {
            sortField.value = 0;
        }
        ZIDX.$("#zidxSearchSortField").val(sortField.value);

        let postObjTemp = ZIDX.getFormDataByFormId("zidxSearchTopForm");
        this.lastPostObj = this.dedupeSearchForm(postObjTemp);
        let searchBoxRect=ZIDX.$(".zidxSearchBox")[0].getBoundingClientRect();
        if (searchBoxRect.width < 992) {
            if (this.lastPostObj.zidx_layout == LISTING_LAYOUT_DETAIL) {
                ZIDX.$("#zidx_layout").val(LISTING_LAYOUT_LIST);
                this.lastPostObj.zidx_layout = LISTING_LAYOUT_LIST;
            }
            ZIDX.$(".zidxSearchDetailLink").hide();
        }
        this.updateSearchFormLabels(this.lastPostObj);
        this.setupListingSearchInfiniteScroll();

        // if(ZIDX.options.debug) console.log("current search:", postObj);
        ZIDX.$(".zidxSearchContainer").css("opacity", 1);
        ZIDX.$(".zidxSearchResultsGridContainer").removeClass("zidxList");
        ZIDX.$(".zidxSearchResultsGridContainer").removeClass("zidxDetail");
        ZIDX.$(".zidxSearchResultsGridContainer").removeClass("zidxMap");
        ZIDX.$(".zidxSearchListLink").removeClass("zidxActive");
        ZIDX.$(".zidxSearchDetailLink").removeClass("zidxActive");
        ZIDX.$(".zidxSearchMapLink").removeClass("zidxActive");
        if (this.lastPostObj.zidx_layout == LISTING_LAYOUT_LIST) {
            ZIDX.$(".zidxSearchListLink").addClass("zidxActive");
            ZIDX.$(".zidxSearchResultsGridContainer").addClass("zidxList");
            ZIDX.$(".zidxSearchMapContainer").hide();
            ZIDX.$(".zidxFooterDisclaimerContainer").css("width", "100%");
        } else if (this.lastPostObj.zidx_layout == LISTING_LAYOUT_DETAIL) {
            ZIDX.$(".zidxSearchDetailLink").addClass("zidxActive");
            ZIDX.$(".zidxSearchResultsGridContainer").addClass("zidxDetail");
            ZIDX.$(".zidxSearchMapContainer").hide();
            ZIDX.$(".zidxFooterDisclaimerContainer").css("width", "100%");
        } else if (this.lastPostObj.zidx_layout == LISTING_LAYOUT_MAP) {
            ZIDX.$(".zidxSearchMapLink").addClass("zidxActive");
            ZIDX.$(".zidxSearchResultsGridContainer").addClass("zidxMap");
            ZIDX.$(".zidxSearchMapContainer").show();
            if (ZIDX.windowSize.width < 767) {
                ZIDX.$(".zidxFooterDisclaimerContainer").css({"float": "left", "width": "100%"});
            }else {
                ZIDX.$(".zidxFooterDisclaimerContainer").css({"float": "left", "width": "50%"});
            }
        }
        this.updateSearchCounts(this.lastPostObj);
        console.log("idxSearchResize fired in loadCurrentSearchCriteria");
        this.idxSearchResize();
        if (this.lastPostObj.zidx_layout == LISTING_LAYOUT_MAP) {
            this.loadMap(function () {
            });
        }
    }

    // setFieldValue(field:string, value:string){
    //     let arrValue=value.split(",");
    //     ZIDX.$("#zidxSearchTopForm input[name='"+field+"'], #zidxSearchTopForm select[name='"+field+"']").each(function(this:any) {
    //         if(this.name!=field){
    //             return;
    //         }
    //         if(this instanceof HTMLSelectElement){
    //             for(let i=0;i<this.options.length;i++){
    //                 if(this.options[i].value==value) {
    //                     this.selectedIndex = i;
    //                     break;
    //                 }
    //             }
    //         }else {
    //             for(let i=0;i<arrValue.length;i++){
    //                 if (this.type == "checkbox" || this.type == "radio") {
    //                     if(this.value==arrValue[i]){
    //                         this.checked=true;
    //                     }
    //                 }else{
    //                     this.value=arrValue[i];
    //                 }
    //             }
    //         }
    //     });
    // }

    searchUpdateMorePopup() {
        // if(ZIDX.options.debug) console.log("searchUpdateMorePopup triggers slideUp");
        ZIDX.$(".zidxSearchPopupDiv").slideUp();
        let rect=ZIDX.$(".zidxSearchMoreLink")[0].getBoundingClientRect();
        // console.log("rect", rect);
        if (this.searchMorePopupOpen) {
            // let top=p.y;//+ p.height
            ZIDX.$(".zidxSearchMorePopup").css({
                "left": +"0px",
                "top": (rect.height+5) + "px",
                "display": "block"

            });
            ZIDX.$("body").addClass("zidxMobileMenuDisableScroll");
            // let newHeight = ZIDX.windowSize.height - p.height - 10 - 95;
            let newHeight = ZIDX.windowSize.height - rect.top- rect.height - 95;
 
            // this.containerPosition=this.options.containerElement.getBoundingClientRect();
            // if(!ZIDX.options.wixApp) {
            //     this.options.containerElement.style.height = (ZIDX.windowSize.height - this.containerPosition.top) + "px";
            // }
            // ZIDX.$(".zidxSearchMorePopupInner").css({"height": "auto"});
            // if (ZIDX.$(".zidxSearchMorePopupInner").height() > newHeight) {
            // console.log("newHeight", newHeight);
            ZIDX.$(".zidxSearchMorePopupInner").css({"height": (newHeight) + "px"});// - 10 - p.height
            // }
            //ZIDX.$(".zidxSearchMorePopupInner").css({"height":(ZIDX.windowSize.height-p.height-10-95)+"px" });

            // let h=(ZIDX.windowSize.height-p.height-5-80);
            // ZIDX.$(".zidxSearchMorePopupFieldContainer").css({"height":h+"px" });
            ZIDX.jumpToId("searchJumpTopForm");

        }
        this.updateSearchCounts(this.lastPostObj);
    }

    updateSearchCounts(postObj: ZIDXSearchFields) {
        let countObj: any = {};
        for (let i in postObj) {
            if (!postObj.hasOwnProperty(i)) {
                continue;
            }
            if (i == "zidx_search_show_listings" || i == "zidx_search_location" || i == "zidx_search_latitude" || i == "zidx_search_longitude" || i == "zidx_offset") {
                continue;
            } else {
                if (typeof postObj[i] == "undefined") {
                    if (ZIDX.options.debug) console.log(i + " is undefined");
                    continue;
                } else if (typeof postObj[i] == "number") {
                    continue;
                }
                let arrValue = (<string>postObj[i]).split(",");
                let arrFinal = [];
                for (let n = 0; n < arrValue.length; n++) {
                    if (arrValue[n] != "") {
                        arrFinal.push(arrValue[n]);
                    }
                }
                postObj[i] = arrFinal.join(",");
                countObj[i] = arrFinal.length;
            }
        }
        ZIDX.$(".zidxSearchPopupLinkArrow").each(function (this: any) {
            let self = this;
            setTimeout(function () {
                // have to delay because count doesn't exist yet.
                let count = 0;
                if (typeof countObj[ZIDX.$(self).attr("data-field")] != "undefined") {
                    count = countObj[ZIDX.$(self).attr("data-field")];
                }
                let arrow = ZIDX.$(self).attr("data-arrow");
                if (typeof arrow == "undefined") {
                    arrow = "&#x25BC;";
                }
                if (count == 0) {
                    ZIDX.$(self).html(arrow);
                } else {
                    ZIDX.$(self).html("(" + count + ") " + arrow);
                }
            }, 10);
        });
    }

    setupSearchFormOnce() {
        if (this.searchFormSetupOnce) {
            return;
        }
        this.searchFormSetupOnce = true;
        let self = this;
        // don't need touchend here
        ZIDX.$(document).on("click", function (this: any, e: ClickEvent) {
            let hitMenu = false;
            if (self.skipMenuHitText) {
                self.skipMenuHitText = false;
                return;
            }
            ZIDX.$(".zidxSiteHome input, .zidxSiteHome select").each(function (this: any) {
                if (ZIDX.mouseHitTest(this, 0)) {
                    hitMenu = true;
                }
            });
            ZIDX.$(".zidxSearchPopupLink").each(function (this: any) {
                if (ZIDX.mouseHitTest(this, 0)) {
                    hitMenu = true;
                }
            });
            ZIDX.$(".zidxSearchPopupLink").each(function (this: any) {
                if (ZIDX.mouseHitTest(this, 0)) {
                    hitMenu = true;
                }
            });
            ZIDX.$(".zidxSearchPopupDiv").each(function (this: any) {
                if (ZIDX.mouseHitTest(this, 0)) {
                    hitMenu = true;
                }
            });
            ZIDX.$(".zidxSearchMorePopupInner").each(function (this: any) {
                if (ZIDX.mouseHitTest(this, 0)) {
                    hitMenu = true;
                }
            });
            ZIDX.$(".zidxSearchMoreLink").each(function (this: any) {
                if (ZIDX.mouseHitTest(this, 0)) {
                    hitMenu = true;
                }
            });
            ZIDX.$(".zidxSearchElementField").each(function (this: any) {
                if (ZIDX.mouseHitTest(this, 0)) {
                    hitMenu = true;
                }
            });
            // sort must be last
            ZIDX.$(".zidxSearchSortField").each(function (this: any) {
                if (ZIDX.mouseHitTest(this, 0)) {
                    if (!hitMenu) {
                        // if(ZIDX.options.debug) console.log("sort triggers slideUp");
                        ZIDX.$(".zidxSearchPopupDiv").slideUp();
                    }
                    hitMenu = true;
                }
            });
            ZIDX.$(".zidxSearchMoreLink").each(function (this: HTMLAnchorElement) {
                if (document.activeElement === this) {
                    hitMenu = true;
                }
            });

            if (!hitMenu) {
                if(ZIDX.$(".zidxSearchMorePopup").css("display")=="block") {
                    if (ZIDX.options.debug) console.log("no hit, closing");
                    ZIDX.$(".zidxSearchPopupDiv").slideUp();
                    console.log("checkForSearchFormChanges 3");
                    self.checkForSearchFormChanges(false, true);
                    if (self.searchMorePopupOpen) {
                        self.closeMorePopup();
                    }
                }
            }
        });
    }

    idxSearchResize() {
        if (ZIDX.$(".zidxSiteHomeContainer.zidxPageActive").length == 0) {
            console.log("disable resize when home page is hidden");
            return; // disable when hidden
        }
        // verify
        this.searchUpdateMorePopup();
        let layout = LISTING_LAYOUT_LIST;
        let $detailLink = ZIDX.$(".zidxSearchDetailLink");
        if ($detailLink.length == 0) {
            return;
        }
        if ($detailLink.hasClass("zidxActive")) {
            layout = LISTING_LAYOUT_DETAIL;
        } else if (ZIDX.$(".zidxSearchMapLink").hasClass("zidxActive")) {
            layout = LISTING_LAYOUT_MAP;
        }
        if (ZIDX.windowSize.width < 992) {
            if (this.lastPostObj.zidx_layout == LISTING_LAYOUT_DETAIL) {
                ZIDX.$(".zidxSearchListLink").trigger("click");
                ZIDX.$("#zidx_layout").val(LISTING_LAYOUT_LIST);
                this.lastPostObj.zidx_layout = LISTING_LAYOUT_LIST;
                layout = LISTING_LAYOUT_LIST;
            }
            if ($detailLink[0].style.display != "none") {
                $detailLink.hide();
                $detailLink.blur();
            }
        } else if ($detailLink[0].style.display == "none") {
            $detailLink.show();
            $detailLink.blur();
        }
        ZIDX.$(".zidxSearchResultsGridContainer").removeClass("zidxList").removeClass("zidxDetail").removeClass("zidxMap").addClass(this.getLayoutString(layout));
        let mapElement = document.getElementById("zidxSearchMap1")!;

        if (layout == LISTING_LAYOUT_LIST || layout == LISTING_LAYOUT_DETAIL) {
            ZIDX.$(".zidxSearchMapContainer").hide();
            let container = ZIDX.$(".zidxSearchResultsGridContainer")[0];
            let containerPosition = container.getBoundingClientRect();//
            // if(!ZIDX.options.wixApp) {zidxSearchResultListingContainer
            // if(searchIsEmbedded) {
            //     // zidxSearchResultListingContainer
            //     ZIDX.$(".zidxSearchResultsGridContainer").css({
            //         "height": (300*3) + "px"
            //     });
            // }else{
            //     ZIDX.$(".zidxSearchResultsGridContainer").css({
            //         "height": (ZIDX.windowSize.height - containerPosition.y) + "px"
            //     });
            // }
            // }
            mapElement.style.display = "none";
            ZIDX.$(".zidxFooterDisclaimerContainer").css({"float":"left","width":"100%"});
        } else if (layout == LISTING_LAYOUT_MAP) {
            if (ZIDX.windowSize.width < 767) {
                ZIDX.$(".zidxFooterDisclaimerContainer").css({"float": "left", "width": "100%"});
            }else {
                ZIDX.$(".zidxFooterDisclaimerContainer").css({"float": "left", "width": "50%"});
            }
            ZIDX.$(".zidxSearchMapContainer").hide();
            let searchResultsContainer = ZIDX.$(".zidxSearchResultsContainer")[0];
            let searchResultsContainerPosition=searchResultsContainer.getBoundingClientRect();
            ZIDX.$(".zidxSearchMapContainer").show();
            let mapContainer = ZIDX.$(".zidxSearchMapContainer")[0];
            let mapHeight=Math.round(ZIDX.windowSize.height*.8);

            // hack for massey
            // let headerRect=ZIDX.$("#header")[0].getBoundingClientRect();
            // mapHeight=mapHeight-headerRect.height;
            mapContainer.style.height = mapHeight+"px"; //searchResultsContainerPosition.height + "px";
            let mapContainerPosition = mapContainer.getBoundingClientRect();
            // console.log("showMap2");
            mapElement.style.display = "block";
            mapElement.style.position="absolute";
            mapElement.style.left = Math.round(mapContainerPosition.x) + "px";
            if (ZIDX.windowSize.width < 767) {
                ZIDX.$(".zidxLoadMoreContainer").hide();
                let marginLeftPercent = (mapContainerPosition.x / ZIDX.windowSize.width) * 100;
                mapElement.style.marginLeft = (-marginLeftPercent) + "%";
                mapElement.style.marginRight = (-marginLeftPercent) + "%";
                mapElement.style.width = (100 ) + "%";
            } else {
                ZIDX.$(".zidxLoadMoreContainer").show();
                mapElement.style.marginLeft = (0) + "px";
                mapElement.style.marginRight = (0) + "px";
                mapElement.style.width = (ZIDX.windowSize.width - mapContainerPosition.x-16) + "px";
            }
            ZIDX.$(".zidxSearchResultsGridContainer").css("min-height", (ZIDX.windowSize.height - searchResultsContainerPosition.y-50)+"px");
            // console.log("map height", ZIDX.windowSize.height - searchResultsContainerPosition.y-50, searchResultsContainerPosition.y);
            mapElement.style.height = mapHeight+"px";// (ZIDX.windowSize.height - searchResultsContainerPosition.y-50) + "px";
            ZIDX.$("#zidx_map_height").val(ZIDX.windowSize.height - searchResultsContainerPosition.y);
            // if(!ZIDX.options.wixApp) {
            // if(searchIsEmbedded) {
            //     ZIDX.$(".zidxSearchResultsGridContainer").css({
            //         "height": (300*3) + "px"
            //     });
            // }else {
            //     ZIDX.$(".zidxSearchResultsGridContainer").css({
            //         "height": (ZIDX.windowSize.height - searchResultsContainerPosition.y) + "px"
            //     });
            // }

            let gridContainer = ZIDX.$(".zidxSearchResultsContainer")[0]!;
            let gridRect = gridContainer.getBoundingClientRect();
            let gridPos=ZIDX.getAbsolutePosition(gridContainer);
            let footerPos=ZIDX.getAbsolutePosition(ZIDX.$("#zidxFooterDisclaimerContainer")[0]);
            let mapRect=ZIDX.$("#zidxSearchMap1")[0]!.getBoundingClientRect();
            let top=gridPos.top;
            if(gridRect.y<=0){
                top=Math.abs(gridRect.y)+gridPos.top;
            }
            if(top+mapRect.height>footerPos.top){
                top=footerPos.top-mapRect.height;
            }
            ZIDX.$("#zidxSearchMap1").css({
                "position": "absolute",
                "top": top + "px"
            });
            // }
        }
    }

    hideMap() {
        // console.log("trying to hideMap mapLoaded 22:"+this.mapLoaded);
        if (!this.mapLoaded) {
            return;
        }
        if (typeof zidxMap != "undefined") {
            zidxMap.hideListingMarker();
            zidxMap.hideMap();
        }
    }

    loadMap(loadCallback: Function) {
        let self = this;
        if (this.mapLoaded) {
            zidxMap.closeInfoWindow();
            zidxMap.displayMapMarkers(false);
            loadCallback();
            return;
        }

        if (!this.googleMapURLLoaded && ZIDX.options.googleMapApiURL!="") {
            let script = document.createElement("script");
            script.src = ZIDX.options.googleMapApiURL;
            // script.onload = function () {
            //     setTimeout(function() {
            //         script = document.createElement("script");
            //         script.src = ZIDX.options.siteDomain + "/js-build/standalone/idxMap.js";
            //         script.onload = function () {
            //             self.googleMapReady = true;
            //         };
            //         document.body.appendChild(script);
            //     }, 100);
            // };
            document.body.appendChild(script);
            this.googleMapURLLoaded = true;
            let mapIntervalID=setInterval(function(){
            // @ts-ignore
                if(window.ZIDXGoogleMapApiReady) {
                    script = document.createElement("script");
                    script.src = ZIDX.options.siteDomain + "/js-build/standalone/idxMap.js";
                    script.onload = function () {
                        self.googleMapReady = true;
                    };
                    document.body.appendChild(script);
                    clearInterval(mapIntervalID);
                }
            }, 50);
        }
        if (!this.googleMapReady) {
            setTimeout(function () {
                self.loadMap(loadCallback);
            }, 10);
            return;
        }

        let mapContainer = document.getElementById("zidxSearchMap1");
        if (mapContainer === null) {
            return;
        }
        if (typeof this.listingData.averageLatitude == "undefined" || this.listingData.averageLatitude == 0) {
            this.listingData.averageLatitude = 39;
            this.listingData.averageLongitude = -97;
        }
        let mapOptions = new IDXMapOptions(mapContainer);
        mapOptions.centerLatitude = this.listingData.averageLatitude;
        mapOptions.centerLongitude = this.listingData.averageLongitude;
        mapOptions.zoom = mapOptions.getMapZoom();
        zidxMap = new IDXMap(mapOptions);
        if(ZIDX.$(".zidxPageActive.zidxSiteHomeContainer").length==0){
            setTimeout(function() {
                // console.log("make map absolute");
                ZIDX.$("#zidxSearchMap1").css("position", "absolute");
            }, 10);
        }else{
            setTimeout(function(){
                // console.log("make map fixed");
                ZIDX.$("#zidxSearchMap1").css("position", "absolute");
            }, 10);
        }
        this.mapLoaded = true;
        loadCallback();
    }

    enableFullscreenLink = true;

    setupSearchForm(loadCurrentSearchFromLocal: boolean) {
        let self = this;
        this.loadCurrentSearchCriteria(loadCurrentSearchFromLocal);

        ZIDX.$("#zidxSearchSortField").on("change", function (this: HTMLSelectElement, e: ChangeEvent) {
            let $sortField = ZIDX.$("#zidx_search_sort");
            $sortField.val(this.options[this.selectedIndex].value);
            $sortField.trigger("change");
        });
        ZIDX.$(".zidxSearchMoreHeading").on("click", function (this: HTMLDivElement) {
            let group = ZIDX.$(this).attr("data-group");
            if (typeof group != "undefined") {
                let $group = ZIDX.$("." + group);
                if ($group.css("display") == "none") {
                    $group.show();
                    console.log("trigger more heading click show");
                    ZIDX.$(".zidxSearchPopupLinkArrow2", this).html("&#x25B2;");
                    ZIDX.$(".zidxSearchCheckboxValues", this).hide();
                } else {
                    $group.hide();
                    console.log("trigger more heading click hide");
                    ZIDX.$(".zidxSearchPopupLinkArrow2", this).html("&#x25BC;");
                    ZIDX.$(".zidxSearchCheckboxValues", this).show();
                }
            }
        });
        ZIDX.$(".zidxSearchCheckboxGroupContainer").on("keyup", function (this: HTMLDivElement, event: KeyUpEvent) {
            if (event.which === 13) {
                ZIDX.$(".zidxSearchMoreHeading", this).trigger("click");
            }
        });
        ZIDX.$(document).on("keyup", function (event: KeyUpEvent) {
            if (event.which === 27) { // escape key
                if (self.searchMorePopupOpen) {
                    self.closeMorePopup();
                }
            }
        });

        let arrPopupLinks = ["zidxSearchPriceContainer", "zidxSearchTypeContainer", "zidxSearchBedroomsContainer", "zidxSearchBathroomsContainer"];

        for (let i = 0; i < arrPopupLinks.length; i++) {
            ZIDX.$("#" + arrPopupLinks[i] + " .zidxSearchPopupLink").on("focus", function () {
                self.skipMenuHitText = true;
                ZIDX.$("#" + arrPopupLinks[i] + " .zidxSearchPopupLink").trigger("click");
                self.skipMenuHitText = false;
                // ZIDX.$("#zidx_search_price_from").trigger("focus");
            });
        }
        let selectKeyDown = false;
        ZIDX.$(".zidxSearchPopupDiv select").on("keydown", function () {
            selectKeyDown = true;
        });
        ZIDX.$(".zidxSearchPopupDiv select").on("keyup", function () {
            selectKeyDown = false;
        });
        let $searchTextFields = ZIDX.$("#zidxSearchTopForm input[type=text]");
        let searchInputTimeoutID: any = 0;
        // $searchTextFields.on("focus", function (this: HTMLInputElement) {
        //     let pos = this.getBoundingClientRect();
        //     ZIDX.$(".zidxSearchMorePopupInner")[0].scrollTo(0, pos.y - 90);
        // });

        function limitWordsAndPhrases(text: string, keywordLimit: number, phraseLimit:number) {
            // split into words
            let arrKeyword = text.split(",");
            let arrNewKeyword=[];
            for(let i=0;i<Math.min(keywordLimit, arrKeyword.length);i++){
                let arrWord=arrKeyword[i].split(" ");
                let arrNewWord=[];
                for(let j=0;j<Math.min(phraseLimit, arrWord.length);j++){
                    arrNewWord.push(arrWord[j].trim());
                }
                arrNewKeyword.push(arrNewWord.join(" "));
            }
            return arrNewKeyword.join(",");

        }
        $searchTextFields.on("input", function (this: any, e: InputEvent) {
            console.log("input", this.id);
            if(this.id=="zidx_search_remarks" || this.id=="zidx_search_exclude_remarks" || this.id=="zidx_search_subdivision" || this.id=="zidx_search_address"){
                let newText = limitWordsAndPhrases(this.value, 32, 10);
                if (newText != this.value) {
                    this.value = newText;
                }
            }else if(this.id=="zidx_search_postal_code" || this.id=="zidx_search_listing_id"){
                let newText = limitWordsAndPhrases(this.value, 32, 1);
                if (newText != this.value) {
                    this.value = newText;
                }
            }
            clearTimeout(searchInputTimeoutID);
            searchInputTimeoutID = setTimeout(function () {
                console.log("checkForSearchFormChanges 4");
                self.checkForSearchFormChanges(false, true);
            }, 250);
        });
        ZIDX.$("#zidxSearchTopForm input[type=hidden], #zidxSearchTopForm input[type=text], #zidxSearchTopForm select, #zidxSearchTopForm input[type=checkbox], #zidxSearchTopForm input[type=radio]").on("change", function (this: any, e: ChangeEvent) {
            let otherId: string;
            // go back to page 1 when search criteria changes
            let offsetField = <HTMLInputElement>document.getElementById("zidx_offset")!;
            offsetField.value = "0";
            if (this.id.substr(0, 10) == "zidx_more_") {
                otherId = "zidx_" + this.id.substr(10, this.id.length - 10);
            } else {
                otherId = "zidx_more_" + this.id.substr(5, this.id.length - 5);
            }
            if (this instanceof HTMLSelectElement) {
                let element = (<HTMLSelectElement | null>document.getElementById(otherId));
                if (element) {
                    element.selectedIndex = this.selectedIndex;
                }
            } else {
                let element = (<HTMLInputElement | null>document.getElementById(otherId));
                if (this.type == "checkbox" || this.type == "radio") {
                    if (this.checked) {
                        if (element && !element.checked) {
                            element.checked = true;
                            // ZIDX.$("#" + otherId).trigger("change");
                        }
                    } else {
                        if (element && element.checked) {
                            element.checked = false;
                            // ZIDX.$("#" + otherId).trigger("change");
                        }
                    }
                } else {
                    if (element && element.value != this.value) {
                        element.value = this.value;
                    }
                }
            }
            if (this.searchMorePopupOpen) {
                console.log("checkForSearchFormChanges 5");
                self.checkForSearchFormChanges(false, true);
            } else {
                if (selectKeyDown) {
                    console.log("checkForSearchFormChanges 6");
                    self.checkForSearchFormChanges(false, true);
                } else {
                    if (this.id == "zidx_search_price_to") {
                        if (ZIDX.options.debug) console.log("trigger close from price to");
                        ZIDX.$(".zidxPopupPrice .zidxSearchPopupApplyLink").trigger("click");
                    } else if (this.id == "zidx_search_bedrooms_to") {
                        if (ZIDX.options.debug) console.log("trigger close from bedrooms to");
                        ZIDX.$(".zidxPopupBedrooms .zidxSearchPopupApplyLink").trigger("click");
                    } else if (this.id == "zidx_search_bathrooms_to") {
                        if (ZIDX.options.debug) console.log("trigger close from bathrooms to");
                        ZIDX.$(".zidxPopupBathrooms .zidxSearchPopupApplyLink").trigger("click");
                    } else {
                        console.log("checkForSearchFormChanges 7");
                        self.checkForSearchFormChanges(false, true);
                    }
                }
            }
        });
        ZIDX.$(".zidxSearchMorePopupResetLink").on("click", function (e: ClickEvent) {
            e.preventDefault();
            ZIDX.$(".zidxSearchSortField, .zidxSearchBox select").each(function (this: HTMLSelectElement) {
                this.selectedIndex = 0;
            });
            ZIDX.$("#zidx_search_sort").val("1");
            ZIDX.$("#zidx_offset").val("0");
            ZIDX.$("#zidx_search_polygon, #zidx_search_latitude, #zidx_search_longitude").val("");
            ZIDX.$(".zidxSearchBox input[type='checkbox']").each(function (this: HTMLInputElement) {
                this.checked = false;
            });
            ZIDX.$(".zidxSearchBox input[type='text']").val("");

            let postObj = ZIDX.getFormDataByFormId("zidxSearchTopForm");
            postObj = self.dedupeSearchForm(postObj);
            self.updateSearchFormLabels(postObj);
            self.closeMorePopup();
            console.log("checkForSearchFormChanges 8");
            self.checkForSearchFormChanges(false, true);
        });

        ZIDX.$(".zidxSearchMorePopupShowLink").on("click", function (e: ClickEvent) {
            e.preventDefault();
            console.log("checkForSearchFormChanges 9");
            self.checkForSearchFormChanges(true, true);
            // if(ZIDX.options.debug) console.log("zidxSearchMorePopupShowLink click");
            self.closeMorePopup();
        });
        ZIDX.$(".zidxSearchPopupLink").on("click", function (this: any, e: ClickEvent) {
            e.preventDefault();
            let div = ZIDX.$(this).attr("data-popup-div");
            let $div = ZIDX.$("." + div);
            ZIDX.$(".zidxSearchPopupLink").each(function (this: HTMLDivElement) {
                let a = this.getAttribute("data-popup-div");
                if (div != a) {
                    // if(ZIDX.options.debug) console.log("slideUp on "+a);
                    ZIDX.$("." + a).slideUp();
                }
            });

            let postObj = ZIDX.getFormDataByFormId("zidxSearchTopForm");
            postObj = self.dedupeSearchForm(postObj);
            self.updateSearchCounts(postObj);
            if (ZIDX.$(this).closest(".zidxSearchMorePopupContainer").length == 0) {
                self.closeMorePopup();
                $div.slideDown("fast");
                if ($div[0].style.display == "block") {
                    ZIDX.$(".zidxSearchPopupLinkArrow", this).attr("data-arrow", "&#x25BC;");
                    console.log("checkForSearchFormChanges 10");
                    self.checkForSearchFormChanges(false, true);
                } else {
                    ZIDX.$(".zidxSearchPopupLinkArrow", this).attr("data-arrow", "&#x25B2;");
                }
                // if(ZIDX.options.debug) console.log("zidxSearchPopupLink click");

                let rect=this.getBoundingClientRect();
                // $div.css({"left": p.x + "px", "top": (p.y + p.height) + "px"});
                $div.css({"left": 0+ "px", "top": (rect.height+5) + "px"});
            } else {
                let field = ZIDX.$(".zidxSearchElementField", ZIDX.$(this).parent().parent());
                field.slideUp("fast");
                if (field[0].style.display == "block") {
                    ZIDX.$(".zidxSearchPopupLinkArrow", this).attr("data-arrow", "&#x25BC;");
                    console.log("checkForSearchFormChanges 11");
                    self.checkForSearchFormChanges(false, true);
                } else {
                    ZIDX.$(".zidxSearchPopupLinkArrow", this).attr("data-arrow", "&#x25B2;");
                }
            }

        });
        ZIDX.$("#zidx_search_location").on("blur", function () {
            console.log("checkForSearchFormChanges 12");
            self.checkForSearchFormChanges(false, true);
        });
        ZIDX.$("#zidxSearchFormGoButton").on("click", function () {
            console.log("checkForSearchFormChanges 13");
            self.checkForSearchFormChanges(false, true);
        });

        // console.log("checkForSearchFormChanges 14");
        // this.checkForSearchFormChanges(false, true);

        ZIDX.$(".zidxSearchPopupApplyLink").on("click", function (this: any, e: ClickEvent) {
            e.preventDefault();
            // if(ZIDX.options.debug) console.log("zidxSearchPopupApplyLink click close");
            ZIDX.$(".zidxSearchPopupDiv").slideUp();
            console.log("checkForSearchFormChanges 15");
            self.checkForSearchFormChanges(false, true);
            if (ZIDX.$(this).closest(".zidxSearchMorePopupContainer").length == 0) {
                self.closeMorePopup();
            }
        });
        let wheelTimeoutId: any = null;
        ZIDX.$(".zidxSearchResultsGridContainer").on("scroll wheel touchmove", function (this: HTMLDivElement, ev: any) {
            if (ZIDX.$(".zidxSearchMoreLink")[0].getBoundingClientRect().width == 0) {
                return; // don't resize when hidden
            }
            clearTimeout(wheelTimeoutId);
            wheelTimeoutId = setTimeout(function () {
                if (self.infiniteScroll != null) {
                    self.infiniteScroll.updatePanelsVisibility();
                }
            }, 200);
        }).trigger("wheel");

        let fullscreenLink = ZIDX.$(".zidxSearchFullscreenLink")[0];
        if (!this.enableFullscreenLink || window.location.href.indexOf('zidxFullscreen=1') != -1) {
            this.enableFullscreenLink = false;
            fullscreenLink.style.display = "none";
        } else {
        }
        // resizeFullscreenLink();
        ZIDX.$(".zidxSearchFullscreenLink").on("click", function (e: ClickEvent) {
            if (window.location.href.indexOf("?") == -1) {
                fullscreenLink.href = window.location.href + "?zidxFullscreen=1";
            } else {
                fullscreenLink.href = window.location.href + "&zidxFullscreen=1";
            }
            fullscreenLink.target = "_blank";
            return true;
            // e.preventDefault();
            // if (document.fullscreenElement) {
            //     self.closeFullscreen();
            // } else {
            //     self.openFullscreen(document.documentElement);
            // }
        });
        let searchResultsResizeTimeoutId: any = null;
        ZIDX.$(window).on("clientresize resize", function (ev: any) {
            if (ZIDX.$(".zidxSiteHomeContainer.zidxPageActive").length == 0) {
                console.log("disable resize when home page is hidden");
                return; // disable when hidden
            }
            // can't do this on ios
            // console.log("idxSearchResize in resize");
            // self.idxSearchResize();
            // resizeFullscreenLink();
            clearTimeout(searchResultsResizeTimeoutId);
            searchResultsResizeTimeoutId = setTimeout(function () {
                self.idxSearchResize();
                // to fix ios, we have to do this in setTimeout
                if (self.mapLoaded) {
                    zidxMap.initMapSearch();
                }
                ZIDX.$(".zidxSearchResultsGridContainer").trigger("wheel");
            }, 50);
        });
        ZIDX.$(".zidxSearchListLink, .zidxSearchDetailLink, .zidxSearchMapLink").on("click", function (this: HTMLAnchorElement, e: ClickEvent) {
            let layoutBackup = ZIDX.$("#zidx_layout").val();
            let layout = ZIDX.$(this).attr("data-layout");
            ZIDX.$("#zidx_layout").val(layout);
            let postObjTemp = ZIDX.getFormDataByFormId("zidxSearchTopForm");
            self.lastPostObj = self.dedupeSearchForm(postObjTemp);
            if (this.target == "_blank") {
                // set layout and search back to how it was
                console.log("opening map search in new window");
                let qs = self.buildNativeSearch(self.lastPostObj);
                ZIDX.$("#zidx_layout").val(layoutBackup);
                let postObjTemp = ZIDX.getFormDataByFormId("zidxSearchTopForm");
                self.lastPostObj = self.dedupeSearchForm(postObjTemp);
                if (ZIDX.cookiesEnabled()) {
                    ZIDX.setCookie({
                        key: "zidxCurrentSearch",
                        value: qs,
                        futureSeconds: 60 * 60 * 30,
                        enableSubdomains: false
                    });
                } else {
                    localStorage.setItem("zidxCurrentSearch", qs);
                }
                this.href = ZIDX.getPrefixURL("/?zidxCurrentSearch=" + escape(qs));
                return true;
            }
            e.preventDefault();
            ZIDX.$(".zidxSearchListLink, .zidxSearchDetailLink, .zidxSearchMapLink").removeClass("zidxActive");
            ZIDX.$(this).addClass("zidxActive");
            ZIDX.$("#zidx_layout").val(layout);
            if (self.infiniteScroll != null) {
                self.infiniteScroll.destroyPanels();
            }


            console.log("idxSearchResize fired in layout click");
            self.idxSearchResize();
            if (self.infiniteScroll != null) {
                self.infiniteScroll.resize();
            }
            let qs = self.buildNativeSearch(self.lastPostObj);
            if (ZIDX.cookiesEnabled()) {
                ZIDX.setCookie({
                    key: "zidxCurrentSearch",
                    value: qs,
                    futureSeconds: 60 * 60 * 30,
                    enableSubdomains: false
                });
            } else {
                localStorage.setItem("zidxCurrentSearch", qs);
            }
            // ZIDX.$("#zidx_offset").val(0);
            ZIDX.$(".zidxSearchResultsGridContainer").scrollTop(0);
            // ZIDX.$(".zidxSearchResultsGridContainer").trigger("wheel");
            if (this.className.indexOf("zidxSearchMapLink") != -1) {
                self.loadMap(function () {
                });
            }
            // self.checkForSearchFormChanges(false, false);
        });
        ZIDX.$(".zidxSearchMoreLink").on("keyup", function (this: HTMLDivElement, event: KeyUpEvent) {
            if (event.which === 13) {
                self.searchMorePopupOpen = true;
                self.searchUpdateMorePopup();
            }
        });
        ZIDX.$(".zidxSearchMoreLink").on("click", function (e: ClickEvent) {
            e.preventDefault();
            if (self.searchMorePopupOpen) {
                self.closeMorePopup();
            } else {
                self.searchMorePopupOpen = true;
                self.searchUpdateMorePopup();
            }
        });
        ZIDX.$(".zidxSearchSaveLink").on("click", (e: ClickEvent) => {
            e.preventDefault();
            console.log("checkForSearchFormChanges 16");
            self.checkForSearchFormChanges(false, true);
            if (self.searchMorePopupOpen) {
                self.closeMorePopup();
            }
            let savedSearchForm = new ZIDXSavedSearchForm();
            savedSearchForm.qs = this.qs;

        });
        ZIDX.$(".zidxSearchMorePopupShowLink").on("click", function (e: ClickEvent) {
            e.preventDefault();
            self.closeMorePopup();
        });
        self.setupSearchFormOnce();
        if (this.mapLoaded) {
            zidxMap.initMapSearch();
        }
        console.log("idxSearchResize fired in setupSearchFormOnce");
        // this.idxSearchResize();
    }

    openFullscreen(elem: HTMLElement) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else {
            // @ts-ignore
            if (elem.webkitRequestFullscreen) { /* Safari */
                // @ts-ignore
                elem.webkitRequestFullscreen();
            } else { // @ts-ignore
                if (elem.msRequestFullscreen) { /* IE11 */
                    // @ts-ignore
                    elem.msRequestFullscreen();
                }
            }
        }
    }

    /* Close fullscreen */
    closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else { // @ts-ignore
            if (document.webkitExitFullscreen) { /* Safari */
                // @ts-ignore
                document.webkitExitFullscreen();
            } else {
                // @ts-ignore
                if (document.msExitFullscreen) { /* IE11 */
                    // @ts-ignore
                    document.msExitFullscreen();
                }
            }
        }
    }

    zso(obj: any, varName: string, isNumber?: boolean, defaultValue?: any) {
        if (typeof isNumber === "undefined") isNumber = false;
        if (typeof defaultValue === "undefined") defaultValue = false;
        let tempVar: any = "";
        if (isNumber) {
            if (this.keyExists(obj, varName)) {
                tempVar = obj[varName];
                if (!isNaN(tempVar)) {
                    return tempVar;
                } else {
                    if (defaultValue !== "") {
                        return defaultValue;
                    } else {
                        return 0;
                    }
                }
            } else {
                if (defaultValue !== "") {
                    return defaultValue;
                } else {
                    return 0;
                }
            }
        } else {
            if (this.keyExists(obj, varName)) {
                return obj[varName];
            } else {
                return defaultValue;
            }
        }
    }

    keyExists(obj: any, key: string) {
        return (key in obj);
    }

    urlAppend(theLink: string, appendString: string) {
        if (theLink.indexOf("?") !== -1) {
            return theLink + "&" + appendString;
        } else {
            return theLink + "?" + appendString;
        }
    }
}

window.zidxBase = new ZIDXBase();